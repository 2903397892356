import SvgNextRightLarge from 'assets/main/button-next-right-large.inline.svg';
import AspectRatio from 'components/AspectRatio';
import { CARD_TYPE } from 'components/pages/index/base/enumsIndex';
import FloatButton from 'components/pages/index/items/FloatButton';
import useLanguage from 'hooks/useLanguage';
import React from 'react';
import tw, { css } from 'twin.macro';

const cssBg = ({ tileBackgroundImageMobile }) => css`
  ${tw`relative bg-no-repeat bg-contain bg-left-top`}
  background-image: url(${tileBackgroundImageMobile});
`;

export default function CardMobile({ tileType, tileHeightMobile, title, description, tileBackgroundImageMobile, videoLink, backgroundColor, onRedirect }) {
	const { language } = useLanguage();

	if (tileType === CARD_TYPE.HALF_WIDTH) {
		return (
			<div style={{ height: `${tileHeightMobile}rem`, backgroundColor }} css={[cssBg({ tileBackgroundImageMobile: `${process.env.GATSBY_FINTEQ_MEDIA_URL}/${tileBackgroundImageMobile}` })]}
					 className="relative cursor rounded-05 p-12 pb-30 mb-35 flex flex-col justify-end items-center">
				<div>
					<p className="w-full text-13 text-grey-1 font-bold">{title}</p>
					<p className="w-full text-grey-4 mt-05">{description}</p>
				</div>

				<FloatButton mode="mobile" onClick={onRedirect}>
					<SvgNextRightLarge/>
				</FloatButton>
			</div>
		);
	}

	if (tileType === CARD_TYPE.YOUTUBE) {
		return (
			<div style={{ backgroundColor }} className="rounded-05 px-12 pb-12 mb-24">
				<p className="w-full text-13 text-grey-1 font-bold py-12">{title}</p>

				<AspectRatio>
					<iframe
						width="100%"
						src={`${videoLink}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&origin=${process.env.GATSBY_SITE_URL}&hl=${language}`}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						title={title}
						frameBorder="0"
						allowFullScreen
					/>
				</AspectRatio>
			</div>
		);
	}

	return (
		<div style={{ height: `${tileHeightMobile}rem`, backgroundColor }} css={[cssBg({ tileBackgroundImageMobile: `${process.env.GATSBY_FINTEQ_MEDIA_URL}/${tileBackgroundImageMobile}` })]}
				 className="relative cursor rounded-05 p-12 pb-30 mb-35 flex items-end justify-center">
			<div>
				<p className="w-full text-14 text-grey-1 font-bold">{title}</p>
				<p className="w-full text-grey-4 mt-05">{description}</p>
			</div>

			<FloatButton mode="mobile" onClick={onRedirect}>
				<SvgNextRightLarge/>
			</FloatButton>
		</div>
	);
}

CardMobile.defaultProps = {
	tileType: CARD_TYPE.FULL_WIDTH,
	backgroundColor: '#F7F7F8',
	height: 20,
	width: 100
};
