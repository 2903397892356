import IconCategory from 'assets/main/icon-category.inline.svg';
import IconFolder from 'assets/main/icon-folder.inline.svg';
import IconTime from 'assets/main/icon-time.inline.svg';
import React from 'react';
import { formatStrings } from 'utils/helper';

export default function FooterSection({ time, category, industries, ...props }) {
	return (
		<div tw="flex mb-07" {...props}>
			<div className="flex items-start justify-center">
				<IconFolder/>
				<div className="mx-03 -mt-px border-r border-solid border-grey-A pr-07 mr-07 text-left">
					{category.map(item => <p key={item.id} className="text-07 leading-10">{item.name}</p>)}
				</div>
			</div>
			<div className="flex items-start justify-center">
				<IconCategory/>
				<div className="mx-03 -mt-px border-r border-solid border-grey-A pr-07 mr-07">
					{industries.map(item => <p key={item.id} className="text-07 leading-10">{item.name}</p>)}
				</div>
			</div>
			<div className="flex items-start justify-center">
				<IconTime/>
				<p className="text-07 leading-10 ml-03 -mt-px">{formatStrings([time, ' min.'])}</p>
			</div>
		</div>
	);
}
