import React from 'react';
import { css } from 'twin.macro';

const cssAspectRatio = css`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

export default function AspectRatio({ children, ...props }) {
	return (
		<div css={[cssAspectRatio]} {...props}>
			{children}
		</div>
	);
}
