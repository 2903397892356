import Footer from 'components/Footer';
import Layout from 'components/Layout';
import DesktopView from 'components/pages/index/view/DesktopView';
import MobileView from 'components/pages/index/view/MobileView';
import { CONST } from 'constants/enums';
import useCommonData from 'hooks/useCommonData';
import useSearchParam, { PARAM } from 'hooks/useSearchParam';
import { QUERY } from 'interfaces/graph';
import React, { useEffect, useState } from 'react';
import { useTransition } from 'react-spring';
import { isMobileScreen } from 'utils/helper';

export default function IndexPage() {
	const { loading: loadingPosts, homePagePosts } = useCommonData(QUERY.HOME_PAGE_POSTS);
	const { loading: loadingCategories, homePageCategories } = useCommonData(QUERY.HOME_PAGE_CATEGORIES);
	const [posts, setPosts] = useState([]);
	const [categoryName, setCategoryName] = useState(null);
	const [isAnimationBlocked, setAnimationBlocked] = useState(false);

	const [ currentCategoryKey ] = useSearchParam(PARAM.key, 0);

	const loading = loadingPosts || loadingCategories;

	// unblock animation from random stuck on spam
	useEffect(() => {
		if (isAnimationBlocked) {
			const timer = setTimeout(() => {
				setAnimationBlocked(false);
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [isAnimationBlocked]);

	useEffect(() => {
		if (homePagePosts?.length) {
			setPosts(homePagePosts);
		}
	}, [homePagePosts]);

	// handle filter posts by key param in url
	useEffect(() => {
		const key = Number(currentCategoryKey) - 1;

		if(homePagePosts?.length && homePageCategories?.length && Number(currentCategoryKey) && key < homePageCategories.length && currentCategoryKey > 0) {
			handleFilterPosts({ target: { value: homePageCategories[key].name } });
		}
	}, [homePagePosts, homePageCategories, currentCategoryKey]);

	const handleFilterPosts = ({ target: { value } }) => {
		if (value === CONST.OPTION.EMPTY) {
			setPosts(homePagePosts);
			setCategoryName(null);
		} else {
			setCategoryName(value);
			setPosts(homePagePosts.filter(item => {
				return !!item.category.find(category => category.name === value);
			}));
		}
		setAnimationBlocked(true);
	};

	const handleUnblockAnimation = () => {
		setAnimationBlocked(false);
	};

	const postsAnimated = useTransition(posts, item => item?.id, {
		initial: { transform: 'scale(1)', opacity: 1 },
		from: { transform: 'scale(0.7)', opacity: 0 },
		enter: { transform: 'scale(1)', opacity: 1 },
		leave: { transform: 'scale(0.7)', opacity: 0 },
		onRest: handleUnblockAnimation
	});

	const isMobile = isMobileScreen();

	return (
		<Layout loading={loading}>
			<div className="layout">
				{isMobile ? (
					<MobileView
						currentCategoryName={categoryName}
						homePageCategories={homePageCategories}
						onFilterPosts={handleFilterPosts}
						postsAnimated={postsAnimated}
					/>
				) : (
					<DesktopView
						isAnimationBlocked={isAnimationBlocked}
						currentCategoryName={categoryName}
						homePageCategories={homePageCategories}
						onFilterPosts={handleFilterPosts}
						postsAnimated={postsAnimated}
					/>
				)}
			</div>
			<Footer tw="mt-30" withFlags />
		</Layout>
	);
}
