export const CARD_TYPE = {
	FULL_WIDTH: 1,
	HALF_WIDTH: 2,
	YOUTUBE: 3
};

export const CARD_SECTION = {
	MAIN: 1, // "Główna"
	NEWS: 2, // "Aktualności"
	MOVIES: 3 // "Filmy"
};
