import { Collapse } from '@mui/material';
import SvgLiCircle from 'assets/general/li-circle-green.svg';
import SelectTextField from 'components/material-ui/SelectTextField';
import { CARD_SECTION } from 'components/pages/index/base/enumsIndex';
import CardMobile from 'components/pages/index/items/CardMobile';
import CardParent from 'components/pages/index/items/CardParent';
import { CONST } from 'constants/enums';
import { useFormik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { compose, path, sortBy } from 'ramda';
import React from 'react';
import { css } from 'twin.macro';

const cssListCircleGreen = css`
  > li {
    display: flex;
    align-items: center;
  }

  > li::before {
    content: url(${SvgLiCircle});
    font-weight: bold;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const sortByKey = sortBy(compose(path(['key'])));

export default function MobileView({ currentCategoryName, homePageCategories, postsAnimated, onFilterPosts }) {
	const { t } = useTranslation();

	const formMain = useFormik({
		initialValues: {
			category: currentCategoryName ?? CONST.OPTION.EMPTY
		},
		enableReinitialize: true
	});

	const postsMain = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.MAIN));
	const postsNews = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.NEWS));
	const postsMovies = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.MOVIES));

	return (
		<div>
			<div className="w-full flex items-center justify-end mt-50 mb-20">
				<SelectTextField
					options={homePageCategories}
					form={formMain}
					variant="outlined"
					name="category"
					idName="name"
					onChange={onFilterPosts}
					clearItemName={t('pageIndex.optionAll')}
					withEmpty
				/>
			</div>

			{!postsAnimated?.length ? (
				<p className="font-medium text-12 p-40 mb-20 flex items-center justify-center">
					{t('pageIndex.postsEmpty')}
				</p>
			) : (
				<div>
					<Collapse in={!!postsMain.length}>
						<div>
							<div className="grid">
								{postsMain.map(({ item, props }) => item && (
									<CardParent key={item.id} {...item} style={props}>
										<CardMobile/>
									</CardParent>
								))}
							</div>
						</div>
					</Collapse>

					<Collapse in={!!postsNews.length}>
						<div>
							<ul css={[cssListCircleGreen]}>
								<li className="text-grey-D text-13 font-bold">{t('pageIndex.news')}</li>
							</ul>
							<div className="grid">
								{postsNews.map(({ item, props }) => item && (
									<CardParent key={item.id} {...item} style={props}>
										<CardMobile/>
									</CardParent>
								))}
							</div>
						</div>
					</Collapse>

					<Collapse in={!!postsMovies.length}>
						<div>
							<ul css={[cssListCircleGreen]}>
								<li className="text-grey-D text-13 font-bold">{t('pageIndex.movies')}</li>
							</ul>
							<div className="grid">
								{postsMovies.map(({ item, props }) => item && (
									<CardParent key={item.id} {...item} style={props}>
										<CardMobile {...item} />
									</CardParent>
								))}
							</div>
						</div>
					</Collapse>
				</div>
			)}
		</div>
	);
}
