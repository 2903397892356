import { useLocation } from '@reach/router';

export const PARAM = {
	key: 'key', // ?key=
};

export default function useSearchParam(name, defaultValue) {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	return [
		queryParams.get(name) ?? defaultValue
	];
}
