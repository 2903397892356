import { Collapse } from '@mui/material';
import SvgLiCircleLarge from 'assets/general/li-circle-green-large.svg';
import SvgRectangles from 'assets/main/btn-with-circles.inline.svg';
import Button from 'components/material-ui/Button';
import { CARD_SECTION, CARD_TYPE } from 'components/pages/index/base/enumsIndex';
import CardDesktop from 'components/pages/index/items/CardDesktop';
import CardParent from 'components/pages/index/items/CardParent';
import { CONST } from 'constants/enums';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { compose, path, sortBy } from 'ramda';
import React from 'react';
import tw, { css } from 'twin.macro';
import { val } from 'utils/helper';

const cssListCircleGreen = css`
  > li {
    display: flex;
    align-items: center;
  }

  > li::before {
    content: url(${SvgLiCircleLarge});
    font-weight: bold;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const sortByKey = sortBy(compose(path(['key'])));

export default function DesktopView({ isAnimationBlocked, homePageCategories, postsAnimated, currentCategoryName, onFilterPosts }) {
	const { t } = useTranslation();

	const handleFilterPosts = categoryName => {
		if (currentCategoryName !== categoryName && !isAnimationBlocked) {
			onFilterPosts({ target: { value: categoryName } });
		}
	};

	const postsMain = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.MAIN));
	const postsNews = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.NEWS));
	const postsMovies = sortByKey(postsAnimated?.filter(post => post.item.section === CARD_SECTION.MOVIES));

	return (
		<div>
			<div className="grid grid-cols-4-auto xl:grid-cols-7-auto gap-10 flex item-center xl:justify-end mt-60 mb-20">
				{homePageCategories?.map(category => (
					<Button
						tw="mx-0 text-10"
						css={[category.name === currentCategoryName ? tw`text-grey-3` : tw`text-light`]}
						mode="main"
						key={category.id}
						onClick={() => handleFilterPosts(category.name)}
					>
						{category.name}
					</Button>
				))}
				<Button
					mode="main"
					tw="px-08 mx-0 w-min"
					onClick={() => handleFilterPosts(CONST.OPTION.EMPTY)}
				>
					<SvgRectangles width="1.2rem" height="1.2rem" fill={val(currentCategoryName ? 'light' : 'grey-3')}/>
				</Button>
			</div>

			{!postsAnimated?.length ? (
				<p className="font-medium text-14 p-40 m-40 flex items-center justify-center">
					{t('pageIndex.postsEmpty')}
				</p>
			) : (
				<div>
					<Collapse in={!!postsMain.length}>
						{/* TODO: problem with container height */}
						<div className="grid grid-cols-2 gap-20">
							{postsMain.map(({ item, props }) => item && (
								<CardParent key={item.id} {...item} style={props} css={[item.tileType === CARD_TYPE.FULL_WIDTH && tw`col-span-2`]}>
									<CardDesktop/>
								</CardParent>
							))}
						</div>
					</Collapse>

					<Collapse in={!!postsNews.length}>
						{/* TODO: problem with container height */}
						<div>
							<ul css={[cssListCircleGreen]}>
								<li className="text-grey-D text-18 font-bold">{t('pageIndex.news')}</li>
							</ul>
							<div className="grid grid-cols-2 gap-20 mb-20">
								{postsNews.map(({ item, props }) => item && (
									<CardParent key={item.id} {...item} style={props} css={[item.tileType === CARD_TYPE.FULL_WIDTH && tw`col-span-2`]}>
										<CardDesktop/>
									</CardParent>
								))}
							</div>
						</div>
					</Collapse>

					<Collapse in={!!postsMovies.length}>
						{/* TODO: problem with container height */}
						<div>
							<ul css={[cssListCircleGreen]}>
								<li className="text-grey-D text-18 font-bold">{t('pageIndex.movies')}</li>
							</ul>
							<div className="grid grid-cols-2 gap-20 mb-30">
								{postsMovies.map(({ item, props }) => item && (
									<CardParent key={item.id} {...item} style={props} css={[item.tileType === CARD_TYPE.FULL_WIDTH && tw`col-span-2`]}>
										<CardDesktop/>
									</CardParent>
								))}
							</div>
						</div>
					</Collapse>
				</div>
			)}
		</div>
	);
}
