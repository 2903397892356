import { ButtonBase } from '@mui/material';
import SvgNextRightLarge from 'assets/main/button-next-right-large.inline.svg';
import SvgNextRightNormal from 'assets/main/button-next-right-normal.inline.svg';
import AspectRatio from 'components/AspectRatio';
import { CARD_TYPE } from 'components/pages/index/base/enumsIndex';
import FloatButton from 'components/pages/index/items/FloatButton';
import FooterSection from 'components/pages/index/items/FooterSection';
import useLanguage from 'hooks/useLanguage';
import React from 'react';
import tw, { css } from 'twin.macro';

const cssBg = ({ tileBackgroundImage }) => css`
  ${tw`relative bg-no-repeat bg-contain bg-right-bottom`}
  background-image: url(${tileBackgroundImage});
`;

export default function CardDesktop({
	tileType,
	tileHeight,
	title,
	titleWidth,
	subtitle,
	subtitleWidth,
	description,
	descriptionWidth,
	tileBackgroundImage,
	videoLink,
	backgroundColor,
	category,
	readingTime,
	industries,
	onRedirect,
	...props
}) {
	const { language } = useLanguage();

	if (tileType === CARD_TYPE.HALF_WIDTH) {
		return (
			<ButtonBase tw="rounded-05 mb-20" onClick={onRedirect}>
				<div
					{...props}
					style={{ height: `${tileHeight}rem`, backgroundColor }}
					css={[cssBg({ tileBackgroundImage: `${process.env.GATSBY_FINTEQ_MEDIA_URL}/${tileBackgroundImage}` })]}
					className="rounded-05 pointer relative cursor p-20 pb-0 flex flex-col justify-start items-center"
				>
					<div className="h-full flex flex-col justify-between">
						<div>
							<p style={{ width: `${titleWidth}%` }} className="text-left text-16 text-grey-1 font-bold">{title}</p>
							<p style={{ width: `${descriptionWidth}%` }} className="text-left text-09 leading-11 text-grey-4 mt-12">{description}</p>
						</div>

						<FooterSection
							time={readingTime}
							category={category}
							industries={industries}
						/>
					</div>

					<FloatButton>
						<SvgNextRightNormal/>
					</FloatButton>
				</div>
			</ButtonBase>
		);
	}

	if (tileType === CARD_TYPE.YOUTUBE) {
		return (
			<div {...props} style={{ backgroundColor }} className="rounded-05 p-20 pb-08">
				<p style={{ width: `${titleWidth}%` }} className="text-16 text-grey-1 font-bold mt-05 mb-10">{title}</p>
				<p style={{ width: `${descriptionWidth}%` }} className="text-08 leading-12 text-grey-4 mb-15">{description}</p>

				<AspectRatio>
					<iframe
						width="100%"
						src={`${videoLink}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&origin=${process.env.GATSBY_SITE_URL}&hl=${language}`}
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						title={title}
						frameBorder="0"
						allowFullScreen
					/>
				</AspectRatio>

				<FooterSection
					tw="mt-15"
					time={readingTime}
					category={category}
					industries={industries}
				/>
			</div>
		);
	}

	// TODO: outside animation.dev, inside tileType -> col-span-2
	// tileType fullWidth
	return (
		<ButtonBase tw="w-full rounded-05 mb-20" onClick={onRedirect}>
			<div
				{...props}
				style={{ height: `${tileHeight}rem`, backgroundColor }}
				css={[cssBg({ tileBackgroundImage: `${process.env.GATSBY_FINTEQ_MEDIA_URL}/${tileBackgroundImage}` })]}
				className="w-full rounded-05 pointer relative cursor p-24 pb-0 flex items-start justify-center"
			>
				<div className="w-full h-full flex flex-col justify-between">
					<div>
						<p style={{ width: `${titleWidth}%` }} className="text-left text-24 leading-30 text-grey-1 font-bold my-10">{title}</p>
						<p style={{ width: `${subtitleWidth}%` }} className="text-left text-12 text-grey-1 font-medium mt-15 mb-07">{subtitle}</p>
						<p style={{ width: `${descriptionWidth}%` }} className="text-left text-08 leading-12 text-grey-4">{description}</p>
					</div>

					<FooterSection
						time={readingTime}
						category={category}
						industries={industries}
					/>
				</div>

				<FloatButton mode="large">
					<SvgNextRightLarge/>
				</FloatButton>
			</div>
		</ButtonBase>
	);
}

CardDesktop.defaultProps = {
	tileType: CARD_TYPE.FULL_WIDTH,
	backgroundColor: '#F7F7F8',
	height: 20,
	titleWidth: 100,
	subtitleWidth: 50,
	descriptionWidth: 50
};
