import { routes } from 'constants/routes';
import { navigate } from 'gatsby';
import useLanguage from 'hooks/useLanguage';
import React from 'react';
import { animated } from 'react-spring';

export default function CardParent({ redirectToLink, children, style, ...props }) {
	const { language } = useLanguage();

	const handleRedirect = () => {
		const isHttp = redirectToLink.startsWith('http');
		const isInternalLink = redirectToLink.startsWith('/');

		if(isHttp) {
			window.open(redirectToLink, '_blank');
		} else if(isInternalLink) {
			const routeCurrentLangObject = routes[language];
			const routePlObject = routes['pl'];
			const pathname = redirectToLink;

			const variable = Object.keys(routeCurrentLangObject).find(item => routeCurrentLangObject[item].includes(pathname));
			const variablePl = Object.keys(routePlObject).find(item => routePlObject[item].includes(pathname));
			navigate(routes[language][variable ? variable : variablePl]);
		}
	};

	const childrenWithProps = React.Children.map(children, child =>
		React.cloneElement(child, { ...props, redirectToLink, onRedirect: handleRedirect })
	);

	return (
		<animated.div style={style} {...props}>
			{childrenWithProps}
		</animated.div>
	);
}
